import React, {
    Fragment,
    useState,
    useCallback,
    useMemo,
    useRef,
    useEffect,
} from "react";

import { Input, Password } from "~/components/Base/BaseInputs";
import { BaseForm, FormLabel } from "~/components/Base/BaseForm";
import { Button, Link } from "~/components/Base/BaseActions";
import Divider from "@material-ui/core/Divider";
import { getErrorMessage } from "@utils/errors";

import { useAuth } from "@api/auth";

interface ICredentialState {
    email: string;
    pass: string;
    newPass: string;
}

type CredentialInputHandlers = Record<
    keyof ICredentialState,
    (val: string) => void
>;

const credentialStateKeys: (keyof ICredentialState)[] = [
    "email",
    "pass",
    "newPass",
];

export const LoginForm: React.FC = () => {
    const { signin, requireNewPass , changePass, loading: userLoading } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [creds, updateCreds] = useState<ICredentialState>({
        email: "",
        pass: "",
        newPass: "",
    });
    const [loading, setLoading] = useState(false);
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setLoading(userLoading);
    }, [userLoading]);

    const handleLogin = useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        try {
            // setLoading(true);
            if (requireNewPass) {
                await changePass(creds.newPass);
            } else {
                await signin(creds.email, creds.pass);
            }
        } catch (err) {
            setError(getErrorMessage(err));
        }
    }, [changePass, creds.email, creds.newPass, creds.pass, requireNewPass, signin]);

    const inputHandlers = useMemo<CredentialInputHandlers>(() => {
        return credentialStateKeys.reduce((acc, key) => ({
            ...acc,
            [key]: (val: string) => {
                if (error) setError(null);
                updateCreds((creds) => ({
                    ...creds,
                    [key]: val,
                }));
            },
        }), {} as CredentialInputHandlers);
    }, [error]);

    return (
        <BaseForm title="Login" error={error}>
            <Input
                id={"input-username"}
                update={inputHandlers["email"]}
                label={"Email"}
                placeholder={"Email"}
                autoComplete={"username"}
                disabled={requireNewPass}
                autoFocus
            />
            <Password
                id={"input-password"}
                update={inputHandlers["pass"]}
                hideLabel={false}
                label={"Password"}
                placeholder={"Password"}
                autoComplete={"current-password"}
                disabled={requireNewPass}
            />
            {requireNewPass && (
                <Fragment>
                    <Divider orientation="horizontal" style={{ width: "100%", marginTop: 10 }} />
                    <FormLabel close label="You need to reset your password" />
                    <Password
                        id={"new-password"}
                        update={inputHandlers["newPass"]}
                        hideLabel={false}
                        label={"New Password"}
                        placeholder={"New Password"}
                        autoComplete={"new-password"}
                    />
                </Fragment>
            )}
            {/* <Link
                grid={{
                    xs: 6,
                }}
                to="/login"
                style={{
                    margin: '0 auto 0 0',
                }}
            >
                {"Don't have an account?"}
            </Link> */}
            <Link
                grid={{
                    xs: 12,
                }}
                to="/forgot"
                style={{
                    margin: "0 0 0 auto",
                }}
            >
                {"Forgot Password?"}
            </Link>
            <Button
                submit
                onClick={!loading && handleLogin || undefined}
                label="Login"
                loading={loading}
                // loading={testLoading}
            />
        </BaseForm>
    );
};